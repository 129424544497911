.RedditFavApp {
    text-align:left;
    margin-top: 50px;
    padding: 0 20px;
  }
  
  .RedditFavApp-header {
    min-height: 100vh;
    align-items: center;
    font-size: 1.5em;
    color: white;
  }

a {
    color: white;
    text-decoration: none;
}

    a:visited {
        color: grey;
    }

    a:hover {
        text-decoration: underline;
    }

button {
    font-size: .7em;
}

button.favourite-button {
    border: none;
    background-color: transparent;
    padding: 0;
}

input {
    font-size: 1.2em;
}

img.subredditLogo {
    width: 50px;
    height: 50px;
    vertical-align: -45%;
}

img.favIcon {
    width: 25px;
    height: 25px;
    vertical-align: bottom;
}

img {
    vertical-align: middle;
}

.postItemList {
    padding: .3em;
    margin: .1em 0;
}